<template>
  <v-container fluid>
    <TableViewComponent
      title="Table référent / fonction digitale / établissement"
      @addItemEvent="onAddItem()"
      addItemLabel="ajouter ?"
      itemLabel="établissement"
      itemsLabel="établissements"
      :items="items"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce motif ?"
      :pagination="false"
      :loading="loading"
      :showAddAction="false"
      :showDeleteAction="false"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete" 
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableReferentFunctions",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      items: [],

      /** les services. */
      service: null,
      serviceActivities: null,
      serviceMetiers: null,
    };
  },
  methods: {
    onAddItem() {},
    async load() {
      try {
        this.loading = true;

        this.items = [];

        let items = await this.service.getAll();

        let activities = await this.serviceActivities.getActivities();

        let metiers = await this.serviceMetiers.getAll();

        items.forEach((r) => {
          let item = JSON.parse(JSON.stringify(r));

          item.activities = activities
            .filter((a) => item.activityIds.includes(a.id))
            .map((a) => a.name)
            .join(", ");

          // Récupère le nom du métier en fonction de son id dans la liste des métiers
          let metierName = "-";
          if (item.metierId) {
            metierName = metiers.find((m) => m.id == item.metierId).name;
          }

          item.metier = metierName;

          //action de consultation
          item.view = () => {
            this.$router.push(
              routes.referentFunction.view.root + "/" + item.id
            );
          };

          //action de modification
          item.edit = () => {
            this.$router.push(
              routes.referentFunction.edit.root + "/" + item.id
            );
          };

          this.items.push(item);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metier",
          default: true,
        },
        {
          text: "Activités",
          align: "start",
          sortable: true,
          value: "activities",
          default: true,
        },
        {
          text: "Statut",
          align: "start",
          sortable: true,
          value: "status",
          default: true,
        },
        {
          text: "Nom interne",
          align: "start",
          sortable: true,
          value: "internalName",
          default: true,
        },

        {
          text: "Adresse",
          align: "start",
          sortable: false,
          value: "fullAddress",
          default: false,
        },
        {
          text: "Département",
          align: "start",
          sortable: true,
          value: "departement",
          default: false,
        },
        {
          text: "Support",
          align: "start",
          sortable: true,
          value: "supportDigitalName",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditEtablishmentReferentFunction];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditEtablishmentReferentFunction, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.DetailEtablishmentReferentFunction, ];
    },
  },
  mounted() {
    this.service = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());

    this.load();
  },
};
</script>

<style>
</style>